import s from './Hero.scss';
import React from 'react';
import {Image, ImageResizeOptions, Text, TextTypography} from 'wix-ui-tpa';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import classNames from 'classnames';
import {HeadingTags} from '@wix/wixstores-client-core/dist/es/src/types/heading-tags';
import {classes as stylableClasses, st} from './Hero.st.css';
import {useStyles} from '@wix/tpa-settings/react';
import {CategoryHeaderLayout} from '../../../types/galleryTypes';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {useStylesParams} from '../../../stylesParamsContext';
import {Clamp} from '../../../common/components/Clamp/Clamp';

export enum HeroDataHook {
  Root = 'HeroDataHook.Root',
  HeroImage = 'HeroDataHook.HeroImage',
  CategoryName = 'HeroDataHook.CategoryName',
  Description = 'HeroDataHook.Description',
}

type HeroProps = IGalleryGlobalProps & IProvidedTranslationProps;

export const Hero: React.FunctionComponent<HeroProps> = withGlobals(
  withTranslations()((props: HeroProps) => {
    const styles = useStyles();
    const stylesParams = useStylesParams();
    const shouldShowCategoryHeaderReadMoreLink = styles.get(stylesParams.gallery_showCategoryHeaderReadMoreLink);
    const {t} = props;

    const {name: categoryName, description: categoryDescription, media} = props.globals.currentCategory;
    const shouldShowImage = styles.get(stylesParams.gallery_showCategoryHeaderImage) && media?.url;
    const shouldShowName = styles.get(stylesParams.gallery_showCategoryHeaderName);
    const shouldShowDescription = styles.get(stylesParams.gallery_showCategoryHeaderDescription) && categoryDescription;
    const shouldShowNameAndDescriptionContainer = shouldShowName || shouldShowDescription;
    const layout = styles.get(stylesParams.gallery_categoryHeaderLayout);
    const {shouldShowMobile} = props.globals;

    const getLayoutClass = () => {
      if (layout === CategoryHeaderLayout.TextInside && shouldShowImage) {
        return s.textInside;
      }

      if (layout === CategoryHeaderLayout.TextBelow) {
        return s.textBelow;
      }

      return s.textBelow;
    };

    return (
      <div className={classNames(s.root, getLayoutClass())} data-hook={HeroDataHook.Root}>
        {shouldShowImage && (
          <div className={s.imageContainer}>
            <Image
              className={stylableClasses.imageRoot}
              data-hook={HeroDataHook.HeroImage}
              src={media.url}
              resize={ImageResizeOptions.cover}
              fluid
            />
          </div>
        )}
        {shouldShowNameAndDescriptionContainer && (
          <div className={classNames(s.categoryInfo, {[s.imageVisible]: shouldShowImage})}>
            <div className={s.nameAndDescriptionContainer}>
              {shouldShowName && (
                <Text
                  tagName={HeadingTags.H1}
                  typography={TextTypography.smallTitle}
                  className={classNames(
                    s.categoryName,
                    st(stylableClasses.categoryNameRoot, {useMobileFont: shouldShowMobile})
                  )}
                  data-hook={HeroDataHook.CategoryName}>
                  {categoryName}
                </Text>
              )}

              {shouldShowDescription && (
                <Clamp
                  className={classNames(s.descriptionContainer, {[s.nameVisible]: shouldShowName})}
                  readMoreLessLinkClassName={classNames(
                    st(stylableClasses.readMoreLinkRoot, {useMobileFont: shouldShowMobile})
                  )}
                  enabled={shouldShowCategoryHeaderReadMoreLink}
                  dataHook={HeroDataHook.Description}
                  readMoreText={t('categoryHeaderReadMoreLink')}
                  readLessText={t('categoryHeaderReadLessLink')}>
                  {categoryDescription}
                </Clamp>
              )}
            </div>
          </div>
        )}
      </div>
    );
  })
);
